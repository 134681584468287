import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from './Select';

import { closePopup } from '../actions/popup';
import { createFetchItemCosts, createFetchProductCost, createUpdate } from '../actions';
import * as actions from '../actions/item';

import { getFullOptionItem, getFullItem } from '../selectors';
import { BASE_ZINDEX } from '../popup-factory';

import { formatMoney, applyMargin, generateUUID, parseMysqlDate, parseDate, removeDuplicatesFromArray } from '../utils';

const _updatedUuidCache = {};
const cacheUUID = () => {
  const uuid = generateUUID();
  _updatedUuidCache[uuid] = true;
  return uuid;
};

class UpdateNetCostPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: !props.item.base_costs,
      selectedCost: 1 == props.item.preferred_pricing ? 'preferred' : null,
      selectedQuantityIndex: null,
      selectedColor: null,
      selectedSize: null,
      checked: false,
      breakdowns: [],
      color_id: '',
      size_id: '',
      base_costs: props.item.base_costs ? props.item.base_costs : null,
    };

    this.onSelectAll = this.onSelectAll.bind(this);
    this.onSelectBreakdown = this.onSelectBreakdown.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleChangeSize = this.handleChangeSize.bind(this);
    this.onSelectCost = this.onSelectCost.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item.base_costs) {
      this.setState({
        loading: false,
        selectedCost: this.state.selectedCost || (1 == nextProps.item.preferred_pricing ? 'preferred' : (nextProps.item.base_costs.length ? nextProps.item.base_costs[0].source.type : null)),
        base_costs: nextProps.item.base_costs
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.item.base_costs) {
      this.props.fetchItemCosts(this.props.item.item_id);
    }
  }

  onSelectAll() {
    this.setState((state, props) => ({ checked: !state.checked, breakdowns: state.checked ? [] : props.item.breakdowns.map(b => b.breakdown_id) }));
  }

  onSelectCost(source, i) {
    this.setState({
      selectedQuantityIndex: i,
      selectedCost: source.type,
      selectedColor: (source.color_id || source.color_id === '') ? source.color_id : null,
      selectedSize: (source.size_id || source.size_id === '') ? source.size_id : null
    });
  }

  onSelectBreakdown(breakdown_id) {
    this.setState((state, props) => state.breakdowns.includes(breakdown_id) ? {
      checked: false,
      breakdowns: state.breakdowns.filter(b => b !== breakdown_id)
    } : {
      checked: state.breakdowns.length === props.item.breakdowns.length -1,
      breakdowns: state.breakdowns.concat(breakdown_id)
    });
  }

  handleChangeColor(e) {
    if(e.value === '') {
      if(this.state.size_id !== '') {
        this.setState({ color_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.size_id === this.state.size_id) });
      }else{
        this.setState({ color_id: e.value, base_costs: this.props.item.base_costs });
      }
    }else{
      if(this.state.size_id !== '') {
        this.setState({ color_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.color_id === e.value && c.source.size_id === this.state.size_id) });
      }else{
        this.setState({ color_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.color_id === e.value) });
      }
    }
  }

  handleChangeSize(e) {
    if(e.value === '') {
      if(this.state.color_id !== '') {
        this.setState({ size_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.color_id === this.state.color_id) });
      }else{
        this.setState({ size_id: e.value, base_costs: this.props.item.base_costs });
      }
    }else{
      if(this.state.color_id !== '') {
        this.setState({ size_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.size_id === e.value && c.source.color_id === this.state.color_id) });
      }else{
        this.setState({ size_id: e.value, base_costs: this.props.item.base_costs.filter(c => c.source.size_id === e.value) });
      }
    }
  }

  render() {
    const { locked, item, index, onClosePopup, onUpdateBreakdown, onUpdateItem, onUpdate } = this.props;
    const { breakdowns, loading, selectedCost, selectedQuantityIndex, selectedColor, selectedSize } = this.state;
    const field_lock = 'unit_cost';

    const hasCosts = item.base_costs && item.base_costs.length;

    const title = loading ? 'Fetching Net Costs' : (hasCosts ? (locked ? 'View Net Costs' : 'Update Net Costs') : 'No Costs Available');

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleUpdate = e => {
      e.preventDefault();
      let costs;
      if(selectedColor || selectedSize) {
        if(selectedColor && selectedSize) {
          costs = item.base_costs.filter(c => selectedCost === c.source.type && selectedColor === c.source.color_id && selectedSize === c.source.size_id)[0].costs;
        }else if(selectedColor && !selectedSize) {
          costs = item.base_costs.filter(c => selectedCost === c.source.type && selectedColor === c.source.color_id)[0].costs;
        }else if(!selectedColor && selectedSize) {
          costs = item.base_costs.filter(c => selectedCost === c.source.type && selectedSize === c.source.size_id)[0].costs;
        }
      }else{
        costs = item.base_costs.filter(c => selectedCost === c.source.type)[0].costs;
      }
      if ('OPTION' === item.parent_type) {
        item.options.forEach((i, index) => {
          if (index < costs.length) {
            onUpdateBreakdown(i.breakdown_id, 'unit_cost', i.unit_cost, costs[index].cost, field_lock, {
              id: i.item_id,
              data: {
                unit_price: applyMargin(costs[index].cost, i.total_margin, Number(i.exchange_rate))
              }
            });
            onUpdateBreakdown(i.breakdown_id, 'total_units', i.quantity, costs[index].quantity, field_lock, {
              id: i.item_id,
              data: {
                total_units: costs[index].quantity
              }
            });
          } else {
            onUpdateItem(i.item_id, 'hidden', item.hidden)(1);
          }
        });
        onUpdateItem(item.item_id, 'preferred_pricing', item.preferred_pricing)('preferred' === selectedCost ? 1 : 0);
      } else if ('PRODUCT' === item.parent_type && selectedQuantityIndex !== null) {
        if (breakdowns.length) {
          item.breakdowns.filter(b => breakdowns.includes(b.breakdown_id)).forEach(b => {
            onUpdateBreakdown(b.breakdown_id, 'unit_cost', b.unit_cost, costs[selectedQuantityIndex].cost, field_lock);
          });
          onUpdateItem(item.item_id, 'preferred_pricing', item.preferred_pricing)('preferred' === selectedCost ? 1 : 0);
        }
      }
      onClosePopup();
    };

    const size = hasCosts ? 'large': 'small';

    return (
      <div className={`reveal ${hasCosts ? 'large' : 'small'}`} style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby="net-cost-title" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="net-cost-title">{title}</h3>
          </div>
        </div>
        {loading ? this.renderLoading() : (hasCosts ? this.renderCosts() : this.renderNoCosts())}
        <a className="alert button" style={{ position: 'fixed', right: locked || loading || !hasCosts ? '1rem' : '6rem', top: '1rem' }} onClick={handleCancel}>Cancel</a>
        {hasCosts && !loading && !locked ? <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={handleUpdate}>Update</a> : null}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="row popup-content column">
        <div className="small-12 columns">
          <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
        </div>
      </div>
    );
  }

  renderNoCosts() {
    const { onClosePopup, index } = this.props;

    const handleClose = e => {
      e.preventDefault();
      onClosePopup();
    };

    return (
      <div className="row popup-content column">
        <div className="small-12 columns">
          Please contact the supplier directly to obtain costs for this product.
        </div>
      </div>
    );
  }

  renderCosts() {
    const { item, locked, onClosePopup, index, onUpdateBreakdown } = this.props;
    const { breakdowns, checked, selectedCost, selectedQuantityIndex, selectedColor, selectedSize } = this.state;

    const headingStyle = {
      background: '#E6E6E6',
    };
    const selectedStyle = {
      background: '#5CA3B6',
    };
    const expiryStyle = {
      fontSize: 'small'
    };

    const getCostHeading = source => {
      const expired = source.date_expiry && parseDate(source.date_expiry) < Date.now();
      if (source.url) {
        return <span><a target="_blank" href={source.url}>{source.description}</a> ({source.currency}) {source.date_expiry ? <span style={expiryStyle}>Expire{expired ? 'd' : 's'}: {parseMysqlDate(source.date_expiry)}</span> : null}</span>;
      } else {
        return <span>{source.description} ({source.currency})</span>;
      }
    };

    const has_colors = item.base_costs[item.base_costs.length - 1].source.color_id ? true : false;
    const has_sizes = item.base_costs[item.base_costs.length - 1].source.size_id ? true : false;

    const color_options = has_colors ? (removeDuplicatesFromArray(item.base_costs.filter(c => c.source.type !== 'PRESENTATION').map(c => c.source.color_id)) || []).map(c => ({ key: c, value: c })) : null;
    const size_options = has_sizes ? (removeDuplicatesFromArray(item.base_costs.filter(c => c.source.type !== 'PRESENTATION').map(c => c.source.size_id)) || []).map(s => ({ key: s, value: s})) : null;

    return (
      <div className="row popup-content column">
        {!locked && <p>The selected costs and quantities will be applied to this item when you click Update.</p>}
        <p>Item Currency: {item.currency_id}</p>
        <div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Source</th>
                {has_colors ?
                  <th style={headingStyle}>
                    <Select options={[{ key: '', value: 'All Colors' }].concat(color_options)} value={this.state.color_id} placeholder="Filter Colors" onChange={this.handleChangeColor} />
                  </th>
                : null}
                {has_sizes ?
                  <th style={headingStyle}>
                    <Select options={[{ key: '', value: 'All Sizes' }].concat(size_options)} value={this.state.size_id} placeholder="Filter Sizes" onChange={this.handleChangeSize} />
                  </th>
                : null}
                {item.base_costs[0].costs.map(c =>
                  <th key={c.quantity} style={{ ...headingStyle, textAlign: 'center' }}>{c.quantity}</th>
                )}
              </tr>
            </thead>
            {this.state.base_costs.map((base_cost, i) =>
              <tbody key={base_cost.source.type + base_cost.source.id + i}>
                <tr style={{ textAlign: 'center' }} onClick={e => 'OPTION' === item.parent_type && this.setState({ selectedCost: base_cost.source.type })}>
                  <th style={{ ...headingStyle, textAlign: 'left' }}>{getCostHeading(base_cost.source)}</th>
                  {has_colors ? <th style={{ ...headingStyle, textAlign: 'left' }}>{base_cost.source.color_id}</th> : null}
                  {has_sizes ? <th style={{ ...headingStyle, textAlign: 'left' }}>{base_cost.source.size_id}</th> : null}
                  {base_cost.costs.map((c, i) =>
                    <td key={i}
                        style={(!has_colors && !has_sizes && selectedCost === base_cost.source.type && ('OPTION' === item.parent_type || i === selectedQuantityIndex)) ||
                              (item.parent_type === 'OPTION' && (has_colors || has_sizes) && selectedCost === base_cost.source.type && selectedColor === base_cost.source.color_id && selectedSize === base_cost.source.size_id) ||
                              (item.parent_type === 'PRODUCT' && (has_colors || has_sizes) && selectedCost === base_cost.source.type && i === selectedQuantityIndex && selectedColor === base_cost.source.color_id && selectedSize === base_cost.source.size_id) ||
                              (item.parent_type === 'PRODUCT' && (has_colors || has_sizes) && selectedCost === 'PRESENTATION' && selectedCost === base_cost.source.type && i === selectedQuantityIndex) ?
                                selectedStyle
                              : null}
                        onClick={e => c.cost !== null ? this.onSelectCost(base_cost.source, i) : null}>{c.cost !== null ? formatMoney(c.cost, 4) : '-'}
                    </td>
                  )}
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {'PRODUCT' === item.parent_type &&
          <div>
            <p>Select all applicable quantity breaks</p>
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={headingStyle}>
                  <th style={{ textAlign: 'center' }}><input type="checkbox" onChange={this.onSelectAll} checked={checked} /></th>
                  <th style={{ textAlign: 'center' }}>Quantity</th>
                  <th style={{ textAlign: 'center' }}>Net Cost</th>
                  <th style={{ textAlign: 'center' }}>Size</th>
                  <th style={{ textAlign: 'center' }}>Color</th>
                </tr>
              </thead>
              <tbody>
                {item.breakdowns.map(b => {
                  const size = item.sizes.filter(s => s.size_id === b.size_id)[0];
                  const size_name = size ? size.size_name : 'TBD';
                  const color = item.colors.filter(s => s.color_id === b.color_id)[0];
                  const color_name = color ? color.color_name : 'TBD';
                  return (
                    <tr key={b.breakdown_id} style={{ textAlign: 'center' }}>
                      <th><input type="checkbox" checked={breakdowns.includes(b.breakdown_id)} onChange={e => this.onSelectBreakdown(b.breakdown_id)} /></th>
                      <td>{formatMoney(b.quantity, 0)}</td>
                      <td>{formatMoney(b.unit_cost, 4)}</td>
                      <td>{size_name}</td>
                      <td>{color_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  item: 'OPTION' === ownProps.item.parent_type ? getFullOptionItem(state, { item_id: ownProps.item.item_id }) : getFullItem(state, { item_id: ownProps.item.item_id })
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  const updateBreakdown = createUpdate('breakdown', {
    request: actions.UPDATE_ITEM_BREAKDOWN_REQUEST,
    success: actions.UPDATE_ITEM_BREAKDOWN_SUCCESS,
    failure: actions.UPDATE_ITEM_BREAKDOWN_FAILURE,
    failure_message: 'Unable to update item'
  });

  return {
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    fetchItemCosts: item_id => dispatch(createFetchItemCosts(item_id)),
    onCreateFetchProductCost: (product_id, currency_id, include_all, alternate_currency_id, item_id) => {
      dispatch(createFetchProductCost(product_id, currency_id, include_all, alternate_currency_id, item_id));
    },
    onUpdateBreakdown: (breakdown_id, field, previous_value, value, field_lock = 'unit_cost', alsoUpdate) => {
      dispatch(updateBreakdown(breakdown_id, field, previous_value, value, { field_lock }, {
        updateUUID: cacheUUID(),
      }));
      if (alsoUpdate) {
        dispatch({
          type: actions.UPDATE_ITEM_REQUEST,
          payload: alsoUpdate,
        });
      }
    },
    onUpdateItem: (item_id, field, previous_value, field_lock = 'unit_cost', alsoUpdate = {}) => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }, {
        updateUUID: cacheUUID(),
        alsoUpdate,
      }));
    }
  };
};

const ConnectedUpdateNetCostPopup = connect(mapStateToProps, mapDispatchToProps)(UpdateNetCostPopup);
export default ConnectedUpdateNetCostPopup;

